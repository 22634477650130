import React from 'react';
import clsx from 'clsx';
import {theme} from '../../../themes/default-theme';
import {Radio as MuiRadio}  from '@material-ui/core';
import {makeStyles}  from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    padding: 10,
    [theme.breakpoints.up('md')]: {
      marginRight: 5,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',

    [theme.breakpoints.up('md')]: {
      width: 32,
      height: 32,
      fontSize: 16,
    },

    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.lightGrey,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
    '&.error': {
      border: `1px solid ${theme.palette.error.form}`,
    },
    '&:before': {
      display: 'block',
      width: 14,
      height: 14,
      borderRadius: 14,
      backgroundColor: theme.palette.vividBlue,
      content: '""',
      margin: 5,
      transform: 'scale(0)',
      transition: 'transform 100ms ease-in-out',

      [theme.breakpoints.up('md')]: {
        margin: 8,
        width: 16,
        height: 16,
        borderRadius: 16,
      }
    }
  },
  checkedIcon: {
    '&:before': {
      transform: 'scale(1,1)',
    },
    // 'input:hover ~ &': {
    //   backgroundColor: '#106ba3',
    // },
  },
});

const Radio = (props) => {
  const classes = useStyles();

  const radioProps = {
    ...props,
    error: undefined
  }

  return (
    <MuiRadio
      className={classes.root}
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={clsx(classes.icon, props.error && 'error')} />}
      {...radioProps}
    />
  )
}
  
export default Radio